<template>
  <div>

    <div v-if="page_loading_full">
      <v-dialog v-model="page_loading_full" hide-overlay persistent>
        <div class="text-center">
          <v-progress-circular
            :size="200"
            :width="5"
            color="error"
            indeterminate
          > กำลังโหลด </v-progress-circular>
        </div>
      </v-dialog>
    </div>

    <div v-if="loading_full">
      <v-dialog v-model="loading_full" persistent>
        <div class="text-center">
            <v-progress-circular
              :size="80"
              :width="5"
              color="error"
              indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>

    <div v-if="page_loading">
      <v-dialog v-model="page_loading" hide-overlay persistent>
        <div class="text-center" :style="{'padding-left': paddingleft+'px'}">
          <v-progress-circular
            :size="200"
            :width="5"
            color="error"
            indeterminate
          > กำลังโหลด </v-progress-circular>
        </div>
      </v-dialog>
    </div>

    <div v-if="loading">
      <v-dialog v-model="loading" persistent>
        <div class="text-center">
            <v-progress-circular
              :size="80"
              :width="5"
              color="error"
              indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>

  </div>
</template>

<script>
  export default {
    props: [
      'page_loading_full',
      'loading_full',
      'page_loading',
      'loading'
    ],
    data: () => ({
      paddingleft: 0,
      size: 'lg',
      window: {
        width: 0,
        height: 0
      }
    }),
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
      this.paddingleft = (this.size=='lg' ||this.size=='xl') ? 256 : 0
    },
    methods: {
      handleResize() {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
        if (this.window.width < 600) {
          this.size = 'xs'
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = 'sm'
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = 'md'
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = 'lg'
        } else if (this.window.width >= 1904) {
          this.size = 'xl'
        }
      }
    }

  }
</script>

<style scoped>
  >>>.v-dialog {
    overflow-y: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
